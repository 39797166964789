import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Eko from "/src/components/img/eko"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

export default ({data}) => (
  <Layout>
    <SEO title="Vývoj okyselení životního prostředí na území ČR" />
    <h1>Vývoj okyselení životního prostředí na území ČR</h1>

    <p>Pod pojmem <strong>okyselení prostředí</strong> si většinou vybavíme kyselé deště v druhé polovině minulého století. Změny v&nbsp;kyselosti prostředí však probíhají i dnes po odsíření uhelných elektráren a rovněž k&nbsp;nim došlo v&nbsp;minulosti, a to i přirozenou cestou.</p>
    <hr />
    <h2>Nárůst okyselení v&nbsp;atlantiku</h2>
    <p>Na začátku holocénu je v&nbsp;<strong>preboreálu</strong> (10–9 tis. let BP) bazický prach (Ca, Na, K), který byl během glaciálu&nbsp; uvolňován mechanickým zvětráváním (velké výkyvy teplot), šířen větrem krajinou, což podmiňuje vznik spraší (10 % Ca + křemitý prach s&nbsp;Fe). To vše je umožněno díky suchému podnebí. Půdy jsou zejména bazické, a to umožňuje migraci stepních druhů (např. koniklec, hlaváček jarní atp.).</p>
    <p>Následující období <strong>boreál</strong> (9–8 tis. let BP) je stále suché, ale teplejší. Především bazifyty migrují z&nbsp;Moravy do Čech (do Českého krasu). Kyselý opad z&nbsp;jehličnatých stromů je kompenzován podrostem.</p>
    <p>Velká změna přichází v&nbsp;<strong>atlantiku</strong> (8–5 tis. let BP), kdy narůstá množství srážek. To způsobuje vymývání vápnitých iontů z&nbsp;půd, které je intenzivní především ve vyšších polohách. Díky tomu klesá pH na výše položených stanovištích intenzivněji a dochází tak k&nbsp;velké diferenciaci stanovišť podle kyselosti.</p>
    <p>Přirozené odvápňování krajiny zvolna stále pokračuje, toto je však například kompenzováno nevědomým prášením vápenci ze stavebního ruchu.</p>
    <hr />
    <h2>Počátky antropogenního ovlivňování kyselosti prostředí</h2>
    <p>Prvním ovlivněním je odlesňování krajiny od neolitu a vytváření umělé kulturní stepi. V&nbsp;těchto odlesněných ekumenách docházelo k&nbsp;rychlému uvolňování a přijímání Ca a nedošlo zde k&nbsp;výraznému odvápnění – pH zde bylo víceméně zachováno. V&nbsp;těchto místech se dnes nacházejí černozemě.</p>
    <p>Od 17. století na našem území dochází k&nbsp;těžbě vitriolových břidlic, při jejichž zvětrávání se uvolňuje H2SO4. Na Plzeňsku se jejich loužením získávala cenná kyselina a dodnes nalezneme v&nbsp;tehdejších lomech jezera se slabou kyselinou sírovou.</p>
    <p>Při prvních aplikacích hnojiv pak docházelo k&nbsp;nežádoucímu okyselení. Při aplikaci tzv. čilského ledku, zdroji dusíku, docházelo ke vzniku kyseliny dusičné v&nbsp;drobných množstvích. Rovněž první průmyslová hnojiva působila nežádoucí okyselení (výzkumy v&nbsp;Německu, vedlejším produktem jsou nervové-paralytické plyny).</p>
    <hr />
    <h2>Produkce oxidu siřičitého</h2>
    <p>Oxid siřičitý vzniká spalováním uhlí a reaguje v&nbsp;atmosféře s&nbsp;vodou a vzniká středně silná a nestálá kyselina siřičitá, která pak dopadá spolu se srážkami jako kyselý déšť na zemský povrch.</p>
    <p>Zpočátku nemusí být vliv této kyseliny patrný, rostliny jsou nejprve schopny poškození opravovat. V&nbsp;případě, že kyselina takto bezprostředně poškozuje rostliny, jde o <strong>přímé okyselení</strong>. Nejnebezpečnější jsou pak mlhy, v kterých mlžné kapičky kyseliny obalí stromy. V&nbsp;zimě je ještě zákeřnější jinovatka a námraza, kdy kyselina leptá rostlinu i několik měsíců.</p>
    <p>Pokud jsou rostliny poškozovány změnou pH přes půdu, jedná se o <strong>nepřímé okyselení</strong> (problémy s&nbsp;enzymy atp.).</p>
    <p>Produkce SO2 měla <strong>masivní dopady</strong> na okyselení prostředí. První odumírání lesů se datuje do roku 1869 v&nbsp;okolí Chomutova, šlo však spíše o nějaký lokální únik látek. Vliv okyselení produkcí SO2 je ve významnější míře na území Česka přítomen od dob 1. republiky.</p>
    <p>Plně se však problém projevuje v&nbsp;60. letech, kdy schnou ve velkém lesy v&nbsp;Krušných horách. Smrky byly naleptávány, produkovali silice a tato vůně nalákala kůrovce, který zde smrky „dorazil“.</p>
    <p>V&nbsp;Krušnohoří (ale i na Ostravsku) se začínají postupně projevovat obrovské problémy s&nbsp;ovzduším, statistiky úmrtnosti a nemocnosti letí nahoru a komunisté se obávají povstání a nepokojů. Přichází s&nbsp;řešením, které odkoukali z&nbsp;Porúří v&nbsp;Německu. Začínají se stavět <strong>vysoké</strong> <strong>komíny</strong>, které zplodiny vynesou pryč od lidí do atmosféry až nad inverzi.</p>
    <p>Ačkoliv to přináší zlepšení v&nbsp;sídlech, toto řešení je pouze dočasné. Do 10 let se množství imisí projevuje, akorát o kus dále. Příkladem je Švédsko, v&nbsp;němž spady z&nbsp;Evropy způsobily okyselení jezer a následné úhyny ryb v&nbsp;nich.</p>
    <hr />
    <h2>Imisní katastrofa v&nbsp;Krušných horách</h2>
    <p>Kyselá depozice neprobíhala díky politice vysokých komínů u sídel, ale v&nbsp;okolních horách. V&nbsp;70. letech masivně odumírají jehličnany v&nbsp;Krušných horách, a to hlavně jedle a smrky. Vůně silic z&nbsp;poškozených stromů nalákala kůrovce, který se zde rozšířil a stromy „dorazil“.</p>
    <p>Stromy byly mimo jiného poškozovány kromě přímého okyselení i okyselením nepřímým, které ztížilo příjem živin, mobilizovalo toxický hliník ze zvětralin, omezilo růst stromů a podpořilo napadení kořenových systémů patogenní houbou.</p>
    <p>Imise však nebyly jediným důvodem úhynu kultur na horách, mezi další faktory patřilo rozšíření monokultur, špatné obhospodařování, přemnožení spárkaté zvěře, nevhodné osivo a další!</p>
    <p>Dokladem špatného hospodaření u nás byly hranice imisních holin v&nbsp;Krušných horách, které se víceméně shodovaly s&nbsp;hranicí státní. Špatné hospodaření je i problémem dneška, kdy je smrk sázen na živné a bazické půdy a jiná nevhodná stanoviště – mezibuněčné prostory jsou vlivem rychlejšího růstu větší a strom je pak snadněji napadnutelný houbami. Velice běžnou je tzv. <strong>červená hniloba</strong>, díky níž se stromy snadněji lámou při silnějším větru.</p>
    <hr />
    <h2>Vliv imisí na další česká pohoří</h2>
    <p>V&nbsp;70. letech začínají odumírat Jizerské hory, a to vlivem polské uhelné elektrárny Turow o výkonu 1000 MW. Stromy zde kolem roku 1979 ve finále nelikviduje kůrovec, ale obaleč modřínový, který žere jehličí a prodělal zde obrovskou populační explozi.&nbsp; Na závětrných stranách hor, kde bylo primární poškození menší, stromy přežívají.</p>
    <p>Obaleč modřínový poté napadá i Krkonoše a přistupuje se k&nbsp;vápnění lesů z&nbsp;letadel. To byl však pro půdu šok a od této akce se upustilo, protože její efekt nebyl jednoznačně příznivý.</p>
    <p>V&nbsp;80. létech začínají usychat i Orlické hory, ty však již byly vlivem plavení dřeva do Kutné hory od středověku přetěžované a ve&nbsp;špatném stavu (již v&nbsp;roce 1550 rozsáhlé holiny).</p>
    <p>V&nbsp;Beskydech se okyselení díky flyši s&nbsp;vápnitým tmelem příliš neprojevilo, v&nbsp;roce 1979 však usychají jedle v&nbsp;Hostýnských vrších (750 m n.m.), což již mnohé lidi vylekalo. Začínají se v&nbsp;lesích vymezovat <strong>imisní pásma</strong> (pásmo A – nejhorší – životnost stromů do 20 let, B – 40 let, C – 60 let, D – 80 let). Zhruba 46 % lesů na území Česka se nacházelo v&nbsp;těchto pásmech.</p>
    <p>Na konci 80. let usychají i kultury na Vysočině a Ždárských vrších, které byly silně přesmrčené. V&nbsp;Jeseníkách byly škody spíše maloplošné díky přítomnosti bazičtějších hornin, rovněž se na vrcholcích nacházely klimaxové smrčiny s&nbsp;původním a odolnějším ekotypem smrku.</p>
    <hr />
    <h2>Kůrovcová kalamita na Šumavě</h2>
    <p>Šumava byla imisemi zasažena málo, vzduch do ní totiž proudil převážně od západu a vyskytuje se zde pouze imisní pásmo D. Nicméně v&nbsp;roce 1985 zde graduje kůrovec, a to kvůli málo odolnému nížinnému ekotypu smrku. V&nbsp;roce 1990 nastává kritická situace, populace kůrovce zažívá obrovskou explozi, mezi lesáky se ujímá označení, že je les nabouchlý broukem.</p>
    <p>Situace na Šumavě je pak zhoršena nekoncepční péčí, nejprve se rozsáhle preventivně kácí, v&nbsp;reakci mimo jiné na to se dostávají do parlamentu Zelení a zastavují rozsáhlé těžby dřeva. Nicméně v&nbsp;roce 2007 přichází orkán Kyril a způsobuje rozsáhlé polomy, které nestíhá NP zpracovat. Na Šumavě se pak opět šíří kůrovec a dochází k&nbsp;návratu k&nbsp;preventivnímu kácení. Tyto změny v&nbsp;přístupu k&nbsp;hospodaření v&nbsp;NP situaci rozhodně nepomohly.</p>
    <p>V&nbsp;současné době je problém s&nbsp;kůrovcem všude a jde o středoevropský problém, kůrovec napadá málo ekologicky odolné smrkové kultury.</p>
    <hr />
    <h2>Odsíření elektráren</h2>
    <p>V&nbsp;reakci na kyselé deště bylo nutné zjednat kroky k&nbsp;nápravě a bylo rozhodnuto, že uhelné elektrárny v&nbsp;Československu je nutno odsířit.</p>
    <p>V&nbsp;USA a na západě šli cestou tzv. <strong>suchého odsíření</strong> za použití vápence. Reakcí se sírou vznikala do té doby nepříliš běžná sádra (pouštní materiál, v&nbsp;Evropě nebyl), a to v&nbsp;takovém množství, že jí byl až nadbytek. Důsledek toho byl takový, že od 70. let se v&nbsp;USA rozmohl sádrovec jako jeden z&nbsp;hlavních stavebních materiálů (sádrokarton).</p>
    <p>Československo se rozhoduje, že nepotřebuje sádru a chce jít vlastní cestou tzv. <strong>mokrého odsíření</strong>. Z&nbsp;tohoto odsíření by byla získávána jako produkt kyselina sírová pro chemický průmysl. Projekt mokrého odsíření se však neustále protahoval a po revoluci byl vývoj zastaven a byly dojednány licence pro západní, suchý způsob odsíření.</p>
    <p>Následky okyselení lze vidět do dnešních dní, nicméně kromě vesnic (nekontrolované spalování nekvalitního paliva, až pětinásobné překročení norem v zimě) okyselení způsobené oxidy síry významně pokleslo. U odsířených elektráren je množství síry o 93 % nižší.</p>
    <hr />
    <h2>Oxidy dusíku</h2>
    <p>V&nbsp;současnosti čelíme především imisím z&nbsp;dopravy, problémové z&nbsp;hlediska kyselosti jsou především oxidy dusíku NOx. To podporuje vznik kyseliny dusičné, která právě snižuje pH prostředí, při fotochemických reakcích taktéž vzniká přízemní ozón.</p>
    <p>Zároveň se dostává do krajiny množství dusíku a tím se celé životní prostředí „hnojí“. Mezi důsledky zvýšení dusíku v&nbsp;krajině patří vyšší přírůstek biomasy například v&nbsp;lesích. Kolem roku 2000 byl prováděn výzkum na Pradědu, přírůstek smrků se zde za pouhé dva roky zvýšil ze 4 cm/rok na 16 cm/rok. Les sice rychleji roste, je ale snáze napadnutelnější houbami a je křehčí (větší mezibuněčné prostory). Taktéž se zvyšuje horní hranice lesa.</p>
    <p>V&nbsp;krajině začíná vznikat kombinace, která zde předtím nebyla. Stanoviště jsou kyselejší, zároveň je ale na nich více dusíku. Co to udělá s&nbsp;naší krajinou nevíme. Tato situace zatím silně svědčí invazivkám, například netýkavce.</p>
    <p>Kyselý spad ovlivňuje mimo organismy i například sochy, u kterých je pak rozpouštěn tmel a mnoho z&nbsp;nich tak bylo schováno do depozitářů (často vystaveny kopie), nebo také konstrukce, které rychleji korodují. Atlas životního prostředí ČSFR z&nbsp;roku 1992 je dobrým přehledem, jaký byl stav životního prostředí na konci socialismu.</p>
    <hr />
    <ImgCon><Eko /><div>
    <h2>Studijn&iacute; materi&aacute;ly Ekologie a ŽP</h2>
    <p>T&eacute;ma <strong>Vývoj okyselení životního prostředí na území ČR</strong> je souč&aacute;st&iacute; <Link to="/ekologie-zivotni-prostredi/">studijn&iacute;ch materi&aacute;lů Ekologie a životní prostředí</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte n&aacute;sleduj&iacute;c&iacute;c&iacute; (2.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/ekologie-zivotni-prostredi/"><button>Rozcestník ekologie a ŽP</button></Link>
    <Link to="/ekologie-zivotni-prostredi/ph-reakce-prostredi/"><button className="inv">&larr; pH reakce prostředí</button></Link>
    <Link to="/ekologie-zivotni-prostredi/nedostatek-nadbytek-zivin/"><button className="inv">Nedostatek a nadbytek živin &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
